import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
// import {ModalController, NavParams} from '@ionic/angular';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';
import {ExternalDocumentsService} from './e-signature.service';
import {ExternalDocument, IExternalDocument, IExternalDocumentField} from '../../models/external_document';
import {IClient} from '../../models/client';
import {ISignedDocument, SignedDocument} from '../../models/signed_document';
import * as _ from 'lodash';
import {listAnimation, listStagger} from '../../animations/slideInListAnimation';
import * as moment from 'moment';
import {TransactionsProvider} from '../../pages/clients/client-detail/providers/finances/transactions.service';
import {ClientDocService} from '../../pages/clients/client-detail/providers/client-doc/client-doc.service';
import {GlobalsService} from '../../globals.service';
import {AwakenModal} from '../../shared/awaken-modal/awaken-modal.component';
import { IUser } from 'src/app/models/user';
import { AuthService } from 'src/app/auth/auth.service';
import { SendForSignatureService } from '../send-for-signature/send-for-signature.service';
import { MissingFieldsFormComponent } from '../send-for-signature/missing-fields-form/missing-fields-form.component';
import { fadeInOutUpDown } from 'src/app/animations/fadeInOutUpDown';
import { ToastService } from 'src/app/providers/toast.service';


@Component({
  selector: 'e-signature',
  templateUrl: './e-signature.component.html',
  styleUrls: ['./e-signature.component.scss'],
  animations: [
    listAnimation,
    listStagger,
    fadeInOutUpDown
  ]
})
export class ESignatureComponent implements OnInit, OnDestroy {
  @Input() client: IClient
  @ViewChild('template', {static: true}) template;
  @ViewChild('missingFieldsForm') missingFieldsForm: MissingFieldsFormComponent;
  iframeCode: SafeHtml;
  user: IUser;
  selectedDocument: ExternalDocument;
  signedDocuments: ISignedDocument[];
  // client: IClient;
  firstName: string;
  lastName: string;
  email: string;
  displayModal: boolean;
  formattedUrl: string | SafeUrl;
  formValues: any;


  constructor(private modalCtrl: ModalController,
              public externalDocumentService: ExternalDocumentsService,
              private transactionService: TransactionsProvider,
              public sendForSignatureService: SendForSignatureService,
              private globals: GlobalsService,
              private authService: AuthService,
              private clientDocService: ClientDocService,
              public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.client) {
      this.signedDocuments = this.clientDocService.getSignedDocuments()
      let name = this.client.name
      if (name) {
        let splitName = name.split(" ")
        this.firstName = splitName[0]
        this.lastName = splitName.slice(1, splitName.length).join(" ")
      }
      this.email = this.client.user?.email
      this.user = this.client.user
    }
    this.fetchDocuments(null)

    console.log(this)
  }

  ngOnDestroy() {
    console.log("destroy")
    this.client = null
    this.sendForSignatureService.setMissingFields([])
  }


  /**
   * Calls service to load documents from the database.
   */
  fetchDocuments(event=null) {
    this.externalDocumentService.setExternalDocuments([])
    this.externalDocumentService.fetchDocuments('in_person').subscribe(documents => {
      documents.forEach(document => {
        let signedDoc = _.find(this.signedDocuments, {external_document_id: document.id})
        if (signedDoc) {
          signedDoc = new SignedDocument(signedDoc)
          document.highestStatus = signedDoc.highestStatus()
          document.signed_at = signedDoc.signed_at
          document.signed_document_id = signedDoc.id
        }
      })
      this.externalDocumentService.setExternalDocuments(documents)
      if (event) event.target.complete()
    })
  }

  handleFormSubmission(event: any) {
    this.formValues = event
    this.displayDocument(this.selectedDocument)
  }

  getFieldValue(field: IExternalDocumentField): string {
    return this.formValues[field.form_helper]
  }

  selectDocument(selectedDocument: ExternalDocument) {
    this.selectedDocument = selectedDocument
    const clientId = this.client?.id || null
    this.externalDocumentService.fetchRequiredFields(clientId, selectedDocument.id).subscribe(fields => {
      console.log(fields);
      console.log(this.missingFieldsForm);

      this.sendForSignatureService.setMissingFields([{ document_id: selectedDocument.id, fields: fields }])
    })
  }


  /**
   * Renders iframe for client to sign electronically by forming the URL based on the chosen externalDocument.
   * @param externalDocument
   */
  displayDocument(externalDocument: IExternalDocument) {
    let document = new ExternalDocument(externalDocument)
    console.log(document);

    let url = document.url
    let client = this.client
    console.log(document)

    for (let field of document.external_document_fields) {
      console.log(field, this.getFieldValue(field));
      url = url.replace(field.url_replace, (this.getFieldValue(field) || ''))
    }

    console.log(url);

    this.formattedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    this.selectedDocument = document

    // if (document.name.includes("Policy Packet") ||
    //     ['Photo Release Form'].includes(document.name)) {
    // }

    if (document.name.includes('Investment Structure')) {
      if (client) {
        let totalPaid = this.transactionService.getTotalPaid()
        let weeksPurchased = this.transactionService.getPurchasedWeeks()

        if (!totalPaid || !weeksPurchased) {
          this.transactionService.fetchTransactions(this.client.id).subscribe((trans) => {
            console.log(trans)
            console.log(this.transactionService.getTotalPaid())
            this.replaceValuesAndProcess(url, document)
          })
        } else {
          this.replaceValuesAndProcess(url, document)
        }
      } else {
        this.replaceValuesAndProcess(url, document)
      }
    }

    this.displayModal = true;
  }


  replaceValuesAndProcess(url, document): void {
    let client = this.client

    if ( client ) {
      url = this.replaceUrlValues(url, "INSERT_WEIGHT_HERE", client.current_weight)
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_HERE", client.current_body_fat)
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_GOAL_HERE", client.body_fat_target2)
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_HERE", client.current_visceral_fat)
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_GOAL_HERE", client.visceral_fat_target2)
      url = this.replaceUrlValues(url, "INSERT_RECOMMENDED_WEEKS_HERE", client.recommended_weeks)
      url = this.replaceUrlValues(url, "INSERT_WEEKS_PURCHASED_HERE", this.transactionService.getPurchasedWeeks())
      url = this.replaceUrlValues(url, "INSERT_AMOUNT_PAID_HERE", this.transactionService.getTotalPaid())
      url = this.replaceUrlValues(url, "INSERT_CONSULTANT_NAME_HERE", client.consultant)
      this.selectedDocument = document
      this.createOrUpdateSignedDocument('viewed_at')
    } else {
      url = this.replaceUrlValues(url, "INSERT_WEIGHT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_BODY_FAT_GOAL_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_VISCERAL_FAT_GOAL_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_AGE_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_RECOMMENDED_WEEKS_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_WEEKS_PURCHASED_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_AMOUNT_PAID_HERE", "")
      url = this.replaceUrlValues(url, "INSERT_CONSULTANT_NAME_HERE", "")
      this.selectedDocument = document
    }

    url += '&hideFormFields=true'

    this.formattedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    console.log(url, this.formattedUrl)
  }


  /**
   * Creates a signed document if one does not already exist.
   */
  createOrUpdateSignedDocument(field: 'viewed_at'|'sent_at'|'signed_at') {
    console.log(this.selectedDocument)

    let signedDocument = new SignedDocument({
      external_document_id: this.selectedDocument.id,
      client_id: this.client.id,
      id: this.selectedDocument.signed_document_id
    })

    signedDocument[field] = moment().format()

    if (this.selectedDocument.highestStatus == null) {
      this.clientDocService.createSignedDocument(signedDocument).subscribe(
        document => {
          this.signedDocuments.push(document)
          this.fetchDocuments()
        },
        err => this.globals.handleResponse(err.error, true)
      )
    } else {
      this.clientDocService.updateSignedDocument(signedDocument).subscribe(() => {
        this.fetchDocuments()
      })
    }
  }


  /**
   * Replaces the URL values with the provided value or replaces the value with an empty string
   * @param url
   * @param field
   * @param value
   */
  replaceUrlValues(url, field, value): string {
    if (value) {
      url = url.replace(field, value.toString())
    } else {
      url = url.replace(field, "")
    }
    return url
  }

  async handleDocumentSigning(docWasSigned: boolean) {
    if (docWasSigned) {
      let modal = await this.modalCtrl.create({
        component: AwakenModal,
        componentProps: {
          title: "Are you sure?",
          subtitle: "This will mark the document as signed in our system.  Only proceed if the document was signed successfully",
          type: "confirm"
        },
        cssClass: "small-modal"
      })
      await modal.present()
      modal.onDidDismiss().then(data => {
        console.log(data)
         if (data && data.data && data.data == 'yes') {
           console.log("signed it")
           this.createOrUpdateSignedDocument('signed_at')
           this.selectedDocument = null
         }
      })
    } else {
      // this.selectedDocument = null
      this.displayModal = false;
    }
  }
}
