import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SendForSignatureService} from '../send-for-signature.service';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'a180-missing-fields-form',
  templateUrl: './missing-fields-form.component.html',
  styleUrls: ['./missing-fields-form.component.scss'],
})
export class MissingFieldsFormComponent implements OnInit, OnDestroy {
  @Output() form_submission = new EventEmitter<any>()
  @Input() allowNotes: boolean;
  initialValues: any;
  missingFieldValues: any = {}
  allFieldsSubscription: Subscription;
  reactiveSignatureForm: FormGroup;
  notes: string;

  constructor(public sendForSignatureService: SendForSignatureService,
              private formBuilder: FormBuilder) {
    console.log(this)
  }

  ngOnInit(): void {
    this.reactiveSignatureForm = this.formBuilder.group({
      notes: null
    })
    this.allFieldsSubscription = this.sendForSignatureService.allFields$.subscribe(values => {
      values.forEach(val => {
        this.missingFieldValues[val.required] = val.value
        this.reactiveSignatureForm[val.required] = [val.value, [Validators.required]]
      })
    })
  }

  ngOnDestroy(): void {
    if(this.allFieldsSubscription) this.allFieldsSubscription.unsubscribe()
  }

  submitForm(): void {
    this.form_submission.emit(this.missingFieldValues)
  }

}
