import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ExternalDocumentsService} from '../../e-signature/e-signature.service';
import {ExternalDocument, IExternalDocument, IMissingFieldError} from '../../../models/external_document';
import {SendForSignatureService} from '../send-for-signature.service';
import {GlobalsService} from '../../../globals.service';
import * as _ from 'lodash'
import {SignedDocumentsService} from '../../signed-document-selector/signed-documents.service';
import {SignedDocument} from '../../../models/signed_document';
import {forkJoin, Observable} from 'rxjs';
import { document } from 'ngx-bootstrap';
import { sign } from 'crypto';

@Component({
  selector: 'a180-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
})
export class DocumentSelectorComponent implements OnInit, OnDestroy {
  @Output() documentSelected = new EventEmitter<{document: ExternalDocument, isSelected: boolean}>()
  @Output() formFields = new EventEmitter<{document_id: number, missingFields: IMissingFieldError[]}>()
  @Input() client_id: number;
  @Input() location_name: string;
  permittedDocuments: ExternalDocument[] = [];
  executedDocuments: SignedDocument[] = [];
  documents: IExternalDocument[];
  toggles: any = {}
  lodash = _;


  constructor(public externalDocumentsService: ExternalDocumentsService,
              public sendForSignatureService: SendForSignatureService,
              public signedDocumentService: SignedDocumentsService,
              private globals: GlobalsService) {}

  ngOnInit() {
    this.setupDocuments()
  }

  ngOnDestroy() {
    this.client_id = null
  }

  setupDocuments() {
    console.log('client id', this.client_id);

    let obs_1 = this.externalDocumentsService.fetchDocuments('in_person', this.location_name)
    let obs_2 = new Observable<any[]>(subscriber => {
      subscriber.next([]);
      subscriber.complete();
    });
    if (this.client_id) obs_2 = this.signedDocumentService.fetchExecutedSignedDocuments(this.client_id)

    forkJoin({externalDocs: obs_1, signedDocs: obs_2}).subscribe(val => {
      this.documents = val['externalDocs']
      this.executedDocuments = val['signedDocs']
      console.log(val)
      this.checkIfSigned()
    })
  }


  checkIfSigned() {
    this.documents.forEach(document => {
      let signedDoc = _.chain(this.executedDocuments)
         .filter({
           external_document: {
             external_document_type_id: document.external_document_type_id,
           },
         })
         .value();

      if (signedDoc.length) {
        signedDoc = new SignedDocument(signedDoc[0])
        document.highestStatus = signedDoc.highestStatus()
        document.signed_at = signedDoc.signed_at
        if (signedDoc.sent_at || signedDoc.viewed_at || signedDoc.signed_at) document.alreadySent = true
        console.log(document, signedDoc);
      }
      this.toggles[document.id.toString()] = false
    })
    this.externalDocumentsService.setExternalDocuments(this.documents)
  }


  /**
   * Emits the selected document and whether the document is selected or de-selected.
   * @param event
   * @param document
   */
  toggleDocumentStatus(event: any|CustomEvent, selectedDocument: ExternalDocument): void {
    if ( !this.toggles[selectedDocument.id.toString()] ) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();

      console.log(1)

      this.externalDocumentsService
        .fetchRequiredFields(this.client_id, selectedDocument.id).subscribe(
          (fields) => {

            console.log(2, fields)

            let criticalError = _.find(fields, {throwError: true})
            if (criticalError) {
              setTimeout(() => {
                this.globals.handleResponse(criticalError.message, true)
                this.sendForSignatureService.toggles[selectedDocument.id.toString()] = false
              })
            } else {
              console.log(3)
              this.toggles[selectedDocument.id.toString()] = true
              this.formFields.emit({document_id: selectedDocument.id, missingFields: fields})
              this.documentSelected.emit({document: selectedDocument, isSelected: true})
            }
          },
          err => {
            let criticalError = _.find(err.error, {throwError: true})
            if (criticalError) {
              setTimeout(() => {
                this.globals.handleResponse(criticalError.message, true)
                this.sendForSignatureService.toggles[selectedDocument.id.toString()] = false
              })
            } else {
              this.toggles[selectedDocument.id.toString()] = true
              this.formFields.emit({document_id: selectedDocument.id, missingFields: err.error})
            }
          }
      )
    } else {
      this.documentSelected.emit({document: selectedDocument, isSelected: false})
    }
  }
}
