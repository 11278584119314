import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ExternalDocument} from '../../models/external_document';
import {SafeUrl} from '@angular/platform-browser';
import {IBasicClient} from '../../models/client';
import {ViewportRuler} from '@angular/cdk/scrolling';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {Subscription} from 'rxjs';
import {GlobalsService} from '../../globals.service';

@Component({
  selector: 'e-signature-card',
  templateUrl: './e-signature.card.html'
})

export class ESignatureCardComponent implements OnInit, OnDestroy {
  @Input() selectedDocument: ExternalDocument;
  @Input() formattedUrl: string | SafeUrl;
  @Input() client: IBasicClient;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  iframeHeight: number;
  orientationSubscription: Subscription;
  initialOrientation: string;
  viewportSizes: number[];

  constructor(private ruler: ViewportRuler,
              private screenOrientation: ScreenOrientation,
              private globals: GlobalsService) {
  }

  ngOnInit() {
    this.initialOrientation = this.screenOrientation.type
    let viewportSize = this.ruler.getViewportSize();
    this.viewportSizes = Object.values(viewportSize)
    this.resizeDocument()
    this.handleOrientationChanges()
  }


  ngOnDestroy() {
    if (this.orientationSubscription) this.orientationSubscription.unsubscribe()
  }


  /**
   * Listens to changes in screen orientation to resize iframe when its modified.
   */
  handleOrientationChanges() {
    this.orientationSubscription = this.screenOrientation.onChange().subscribe( () => {
      this.resizeDocument()
    })
  }


  /**
   * Changes the iframe height based on the viewport size.
   */
  resizeDocument() {
    let orientation = this.screenOrientation.type

    if (this.viewportSizes) {
      if (orientation.includes("portrait")) {
        this.iframeHeight = Math.max(...this.viewportSizes) - 111
      } else if (orientation.includes("landscape")) {
        this.iframeHeight = Math.min(...this.viewportSizes) - 111
      }
    } else {
      this.iframeHeight = 900
    }
  }


  dismiss(docWasSigned: boolean = false) {
    this.close.emit(docWasSigned)
  }
}
