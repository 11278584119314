export interface IExternalDocument {
  template_id: string;
  name: string;
  url: string;
  id: number;
  source: string;
  signed_at?: string;
  alreadySent?: boolean;
  signed_document_id?: number;
  external_document_type_id?: number;
  highestStatus?: { field: string; date: string };
  external_document_fields?: IExternalDocumentField[];
}

export interface IExternalDocumentField {
  error_message?: string;
  form_helper?: string;
  name?: string;
  name_pretty?: string;
  required?: boolean;
  url_replace?: string;
}

export class ExternalDocument implements IExternalDocument {
  public id: number;
  public template_id: string;
  public name: string;
  public url: string;
  public source: string;
  public alreadySent?: boolean;
  public signed_at?: string;
  public signed_document_id?: number;
  public external_document_type_id?: number;
  public highestStatus?: {field: string, date: string};
  public external_document_fields?: IExternalDocumentField[];

  constructor(externalDocument: IExternalDocument) {
    Object.entries(externalDocument).forEach(entry => {
      this[entry[0]] = entry[1]
    })
  }
}

export interface IMissingFieldError {
  message: string;
  required: string;
  type?: string;
  throwError?: boolean;
  value?: any;
  hint?: any;
  optional?: boolean;
  options?: string[];
}
