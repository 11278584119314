import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SendForSignatureComponent} from './send-for-signature.component';
import {IonicModule} from '@ionic/angular';
import {DocumentSelectorComponent} from './document-selector/document-selector.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonCardModule} from '../common-card/common-card.module';
import {MissingFieldsFormComponent} from './missing-fields-form/missing-fields-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SendForSignatureContainerComponent} from './send-for-signature-container/send-for-signature-container.component';



@NgModule({
  declarations: [
    SendForSignatureComponent,
    DocumentSelectorComponent,
    SendForSignatureContainerComponent,
    MissingFieldsFormComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    CommonCardModule
  ],
  exports: [
    SendForSignatureComponent,
    MissingFieldsFormComponent
  ]
})
export class SendForSignatureModule { }
